<template>
  <v-btn
    v-bind="$attrs"
    @click="click"
    dark
    rounded
    style="font-size: 16px"
    depressed
  >
    <slot />
  </v-btn>
</template>
<style scoped></style>
<script>
export default {
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  },
}
</script>
