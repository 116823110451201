<template>
  <v-app>
    <v-main :style="{ background: this.$constants.backgroundColor }">
      <router-view
        style="height: 100%; width: 100%; overflow: auto"
      />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>
