import Vue from 'vue'

Vue.prototype.$constants = {
  mainColor: '#6170E8',
  backgroundColor: '#ECEDFA',
  richMenuType: Object.freeze({
    supervisor: 0, // parent
    staff: 1, // staff
    staff_supervisor: 2 // supervisor of staff
  })
}

export default (context) => {
  context.$constants = Vue.prototype.$constants
}
