import Vue from 'vue'
import VueRouter from 'vue-router'

// 管理画面
import ManagerIndex from '../views/manager/Index.vue'
import ManagerSignIn from '../views/manager/SignIn.vue'
import CompanyMaster from '../views/manager/company/Index.vue'
import RichmenuSampleSetting from '../views/manager/richmenu/Setting.vue'

// License
import ErrorPage from '../views/Error.vue'

import i18n from '../plugins/i18n'

// メンテナンス中画面
import Maintenance from '../views/Maintenance.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/manager/signin',
  },
  {
    path: '*',
    name: 'error404',
    component: ErrorPage,
    props: {
      errorCode: '404',
      errorMessage: i18n.t('error_page.404_error'),
    },
  },
  {
    path: '/Maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },

  {
    path: '/manager',
    name: 'ManagerIndex',
    component: ManagerIndex,
    children: [
      {
        path: '/manager/company/master',
        name: 'CompanyMaster',
        component: CompanyMaster,
      },
      {
        path: '/manager/richmenu/setting',
        name: 'RichmenuSampleSetting',
        component: RichmenuSampleSetting,
      },
    ],
  },
  {
    path: '/manager/signin',
    name: 'ManagerSignIn',
    component: ManagerSignIn,
  },
  {
    path: '/500',
    component: ErrorPage,
    props: {
      errorCode: '500',
      errorMessage: i18n.t('error_page.500_error'),
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  },
  routes,
})

/**
 * 画面遷移後にタイトルを更新する
 */
router.afterEach((to) => {
  let str = process.env.VUE_APP_TITLE
  if (to.meta.title) {
    str = to.meta.title
  }
  document.title = str
})

export default router
