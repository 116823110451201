<template>
    <div style="height: 100%">
      <v-card style="min-height: 100%; height: 100%">
        <div style="height: 100%">
          <v-card-title class="pb-10" style="padding-left: 20px;">
            {{ $t('richmenu_setting.title') }}
          </v-card-title>
          <v-form ref="richmenuData" style="padding-left: 16px;">
            <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('richmenu_setting.type') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0 d-flex">
              <v-select
                v-model="selectedRichMenuType"
                :items="richMenuList"
                item-text="name"
                item-value="type"
                label="RichMenu"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text">
                <span class="span-color-line-card">*</span>JSON
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0 d-flex">
                <v-textarea
                v-model="richmenuData.richmenu_json"
                outlined
                clearable
                background-color="#EEEEEE"
                class="custom-textarea"
                height="500"
                :error-messages="jsonError"
                />
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text">
                {{ $t('richmenu_setting.richmenu_image') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0 d-flex">
                <v-file-input v-if="showUploadButton"
                accept="image/png, image/jpeg"
                small-chips
                :label="$t('richmenu_setting.file_richmenu_image')"
                @change="handleFileUpload"
                :error-messages="imageError"
                ></v-file-input>
              <div class="pb-3 col-md-3 col-3" v-if="imageUrl">
                <v-img
                  class="mt-2 img-preview"
                  :src="imageUrl"
                >
                  <v-btn
                    icon
                    class="float-right"
                    @click.stop="deleteFile(imageUrl)"
                  >
                    <v-icon size="30">mdi-close-circle-outline </v-icon>
                  </v-btn>
                </v-img>
              </div>
            </v-col>
          </v-row>
            <!-- action -->
            <v-card-actions class="text-center d-block my-3 pb-5">
                <ENJIBtn
                @click="submit"
                color="#6170E8"
                class="px-9 ml-3 btn-font-size-line-card"
                :class="{ 'disable-events': disableUpdateButton }"
                >
                {{ $t('button.save') }}
                </ENJIBtn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </div>
  </template>
  
  <script>
  import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

  export default {
    components: {
        ENJIBtn
    },
    data() {
        return {
            selectedRichMenuType: 0,
            richMenuList: [
                {
                type: this.$constants.richMenuType.supervisor,
                name: this.$t('richmenu_setting.supervisor_richmenu'),
                },
                {
                type: this.$constants.richMenuType.staff_supervisor,
                name: this.$t('richmenu_setting.supervisor_richmenu_of_staff'),
                },
                {
                type: this.$constants.richMenuType.staff,
                name: this.$t('richmenu_setting.staff_richmenu'),
                },
            ],
            richmenuData: {
              id: '',
              richmenu_json: ''
            },
            selectedFile: null,
            imageUrl: null,
            showUploadButton: true,
            required: (value) => !!value || this.$t('alert.required_text'),
            jsonError: '',
            imageError: '',
            isChangeSelectedTpye: false
        }
    },
    created() {
        this.getSampleRichmenuByType(this.selectedRichMenuType)
    },
    computed: {
      disableUpdateButton() {
        return this.richmenuData.richmenu_json == null || this.jsonError != '' || this.imageError != '' || (this.selectedFile == null && this.imageUrl == null)
      },
    },
    watch: {
      selectedRichMenuType(newVal) {
        this.isChangeSelectedTpye = true
        this.richmenuData.richmenu_json = ''
        this.selectedFile = null
        this.imageUrl = null
        this.showUploadButton = true
        this.jsonError = ''
        this.imageError = ''

        this.getSampleRichmenuByType(newVal)
      },
      'richmenuData.richmenu_json': function(newJson) {
        if (this.isChangeSelectedTpye && !newJson) {
          return
        }
        if (!newJson) {
          this.jsonError = this.$t('alert.required_text')
        } else if (this.isJson(newJson)) {
          this.jsonError = ''
        } else {
          this.jsonError = this.$t('richmenu_setting.invalid_json')
        }
      }
    },
    methods: {
        isJson(str) {
          try {
            JSON.parse(str);
            return true;
          } catch (error) {
            return false;
          }
        },
        getSampleRichmenuByType(richmenuType) {
            this.showLoading({ show: true })
            this.$cognito
            .isAuthenticated()
            .then(async (auth) => {
            this.$axiosM
                .post(
                '/sample/info',
                {
                    richmenu_type: richmenuType,
                },
                {
                    headers: {
                        Authorization: auth.idToken,
                    },
                }
                )
                .then((response) => {
                    this.showLoading({ show: false })
                    if (response.data.length > 0) {
                        this.richmenuData = response.data[0]
                        this.getSampleRichmenuImage(this.richmenuData.media_id, auth.idToken)
                    } else {
                      this.richmenuData = {
                        id: '',
                        richmenu_json: ''
                      }
                      this.imageUrl = null
                      this.selectedFile = null
                    }
                    this.isChangeSelectedTpye = false
                })
                .catch((error) => {
                    this.showLoading({ show: false })
                    if (error.response.status === 500) {
                        //redirect to page error 500
                        this.$router.replace('/500')
                        return
                    }
                    this.showSnack({
                        text: this.$t('alert.error_get_data_failed'),
                        color: 'error',
                        timeout: 6000,
                    })
                })
            })
            .catch((error) => {
                console.error(error)
                this.showLoading({ show: false })
                this.showSnack({
                    text: this.$t('alert.login_again'),
                    color: 'error',
                    timeout: 6000,
                })
                this.$router.replace('/manager/signin')
            })
        },
        getSampleRichmenuImage(mediaId, authToken) {
          this.$axiosM
            .post(
            '/sample/richmenuImage',
            {
              media_id: mediaId,
            },
            {
              headers: {
                Authorization: authToken,
              },
            }
            )
            .then((response) => {
              this.showUploadButton = false
              this.imageUrl = response.data
            })
        },
        handleFileUpload(file) {
          this.imageError = ''
          this.selectedFile = file
          if (this.selectedFile == null) {
            return
          }
          // validate file: https://developers.line.biz/en/reference/messaging-api/#upload-rich-menu-image-requirements
          const maxSize = 1024 * 1024
          if (file.size > maxSize) {
            this.imageError = this.$t('richmenu_setting.over_1MB')
          }
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = evt => {
            let image = new Image()
            let tempError = ''
            image.onload = () => {
              const width = image.width
              if (width < 800 || width > 2500) {
                  tempError = this.$t('richmenu_setting.image_width')
              }
              const height = image.height
              if (height < 250) {
                  tempError = this.$t('richmenu_setting.image_hieght')
              }
              const ratio = width / height
              if (ratio < 1.45) {
                  tempError = this.$t('richmenu_setting.image_ratio')
              }
              if (tempError === '') {
                  this.imageUrl = image.src
                  this.showUploadButton = false
              }
              this.imageError = tempError
            }
            image.src = evt.target.result
          }
          reader.onerror = evt => {
            console.error(evt)
            this.imageError = this.$t('richmenu_setting.invalid_image')
          }
        },
        deleteFile() {
          this.showUploadButton = true
          this.imageUrl = null
        },
        submit() {
            this.showLoading({ show: true })
            this.$cognito.isAuthenticated().then(async (auth) => {
            if (this.selectedFile) {
              this.richmenuData.media_id = await this.uploadImageToS3(auth, this.selectedFile)
            }
            this.$axiosM
            .post(
              '/sample/updateRichmenu',
              {
                id: this.richmenuData.id,
                media_id: this.richmenuData.media_id,
                richmenu_json: this.richmenuData.richmenu_json.trim(),
                richmenu_type: this.selectedRichMenuType
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.showLoading({ show: false })
              this.richmenuData = response.data
              this.selectedFile = null
              this.showSnack({
                text: this.$t('alert.update_success'),
                timeout: 6000,
              })
            })
            .catch((_error) => {
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.update_failed'),
                timeout: 6000,
              })
            })
        })
        },
        async uploadImageToS3(auth, file) {
            try {
                // Get SignedURL
                const response = await this.$axiosM.post(
                    '/sample/upload/presignedurl',
                    {
                        type: file.type,
                    },
                    {
                        headers: {
                            Authorization: auth.idToken,
                        },
                    }
                );

                // Upload file by SignedURL
                const uploadConfig = {
                    headers: {
                        'Content-Type': file.type,
                    },
                    timeout: 600000, // Timeout set to 10 minutes
                };
                await this.$axiosM.put(response.data.signedUrl, file, uploadConfig);

                // Return mediaId
                return response.data.mediaId;
            } catch (error) {
                console.error('Error uploading image to S3:', error);
                throw error; // Re-throw the error to be handled by the caller
            }
        }
    }
  }
  </script>
  <style scoped>
  ::v-deep(.v-text-field--outlined > .v-input__control > .v-input__slot) {
    min-height: 40px;
  }
  ::v-deep(.v-input--switch .v-input--selection-controls__input) {
    margin-right: 0;
  }
  ::v-deep(.v-input.v-select > .v-input__control) {
    border-radius: 5px 0 0 5px;
  }
  
  ::v-deep(.v-messages__wrapper > .v-messages__message) {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
  /* custom style input outer */
  .company-name .v-input__append-outer {
    margin-top: 0 !important;
  }
  .custom-textarea .v-input__control fieldset {
    border: none !important;
  }
  .bg-white {
  background-color: #ffffff !important;
  }
  .img-preview {
    width: 100%;
    aspect-ratio: 78/70;
    border: 1px solid #707070;
  }
  </style>
  