<template>
  <div>
    <MenuLeft :menuTops="mainMenu" />
    <div class="main-container">
      <router-view style="height: 100%;" />
    </div>
    <snackbar-vue />
    <loading-message-vue />
  </div>
</template>

<script>
import SnackbarVue from '@/components/Snackbar.vue'
import LoadingMessageVue from '@/components/LoadingMessage.vue'
import MenuLeft from '@/components/Menu.vue'
export default {
  components: {
    SnackbarVue,
    LoadingMessageVue,
    MenuLeft,
  },
  data() {
    return {
      mainMenu: [
        {
          title: this.$t('navbar.menu_admin_management'),
          path: '/manager/company/master',
        },
        {
          title: this.$t('navbar.menu_admin_richmenu'),
          path: '/manager/richmenu/setting'
        }
      ],
    }
  },
  created() {
    this.isAuthenticated()
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then((auth) => {
          if (!this.$cognito.isAdministrator(auth.groups)) {
            this.$router.replace('/manager/signin')
          }
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
  },
}
</script>

<style>
.main-container {
  padding: 40px;
  height: 100%;
  overflow: hidden;
}
</style>
