import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import constants from './plugins/constants'
import cognito from './plugins/cognito'
import vuetify from './plugins/vuetify'
import '../src/css/common.css'
import gv from './mixins/globalValiables'
import AxiosPlugin from './plugins/axios'
import i18n from './plugins/i18n'

Vue.use(AxiosPlugin)

import VConsole from 'vconsole'

Vue.config.productionTip = false


Vue.mixin(gv)

if (process.env.VUE_APP_SHOW_CONSOLE === 'true') {
  // vconsole
  new VConsole()
}

// Error Handling
Vue.config.errorHandler = (err, _vm, _info) => {
  // メンテナンス中
  if (!err.response.status || err.response.status == 503)
    router.replace({ name: 'Maintenance' })
}

async function main() {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    constants,
    cognito,
    render: function (h) {
      return h(App)
    },
  }).$mount('#app')
}
main()
