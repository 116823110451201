<template>
  <div>
    <v-container fluid class="pa-0 company-master-container">
      <div>
        <v-card v-if="status === 0" class="main mx-auto">
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="4">
                <InputSearch height="44" v-model="search" />
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <ENJIBtn
                  color="#6170EB"
                  height="44"
                  class="px-6"
                  @click="editItem({ id: '', name: '' })"
                >
                  {{ $t('master.create_new') }}
                </ENJIBtn>
              </v-col>
            </v-row>
          </v-card-title>
          <hr size="2" style="border-top: 1px solid #d0d0d0" />
          <v-data-table
            height="66vh"
            :page.sync="page"
            :headers="headers"
            :items="companyList"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-filter="customFilter"
            :no-results-text="$t('alert.not_found')"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            fixed-header
            :loading-text="$t('alert.loading')"
            class="elevation-1"
            item-style="font-size: 16px"
          >
            <template #[`item.edit`]="{ item }">
              <v-icon class="mr-3" @click="editItem(item)"> mdi-pencil </v-icon>
            </template>
            <template #[`item.name`]="{ item }">
              <span :style="{ color: item.deleted ? 'red': ''} ">{{ item.name }}</span>
            </template>
          </v-data-table>
          <Pagination v-model="page" :length="pageCount" />
        </v-card>
      </div>
    </v-container>
    <!-- dialog -->
    <div v-if="dialog">
      <v-dialog
        persistent
        v-model="dialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <CompanyForm
          v-on:closeDialog="onCloseDialog"
          v-on:createCompany="onCreateCompany"
          :company="company"
          :companyList="companyList"
          @delete="deleteCompany"
        >
        </CompanyForm>
      </v-dialog>
    </div>
  </div>
</template>
<style>
/*== custom container ==*/
.company-master-container .v-sheet.v-card {
  border-radius: 0px 12px 12px 12px;
}
.company-master-container .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

/*== custom Table ==*/
.elevation-1.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #5d6060;
  font: normal normal normal 16px/18px Noto Sans JP;
  font-size: 16px;
}
.elevation-1.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  font: normal normal normal 16px/18px Noto Sans JP;
  color: #161a1a;
  min-height: 57px;
  height: 57px;
}

.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 10px;
  padding-right: 0;
}
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(2) {
  padding-left: 6px;
  padding-right: 16px;
}
</style>

<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import Pagination from '@/components/Pagination.vue'
import CompanyForm from '@/components/CompanyForm.vue'

export default {
  components: {
    ENJIBtn,
    InputSearch,
    Pagination,
    CompanyForm,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      status: -1,
      dialog: false,
      company: {},
      dialogTransition: 'fade-transition-right',
      companyList: [],
      search: '',
      sortBy: 'sortNo',
      sortDesc: false,
      headers: [
        {
          text: '',
          value: 'edit',
          align: 'left',
          width: '10',
          sortable: false,
        },
        {
          text: this.$t('master.company'),
          value: 'name',
          align: 'left',
        },
      ],
      editedIndex: -1,
      loading: true,
      page: 1,
      itemsPerPage: 100,
    }
  },
  created() {
    this.isAuthenticated()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.companyList.length / this.itemsPerPage)
    },
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(() => {
          this.initialize()
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    initialize() {
      this.loading = false
      this.status = 0
      this.showLoading({ show: true })
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/company/list',
              {},
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then(({ data }) => {
              this.loading = false
              this.showLoading({ show: false })
              this.companyList = data
              this.status = 0
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
              this.showLoading({ show: false })
              if (error.response.status === 500) {
                //redirect to page error 500
                return this.$router.replace('/500')
              }
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    customFilter(value, search, item) {
      return !!search && item.name.toLowerCase().includes(search.toLowerCase())
    },
    editItem(value) {
      if (value.id) {
        this.editedIndex = this.companyList.indexOf(value.id)
      }
      this.company = Object.assign({}, value)
      this.dialog = true
    },
    onCloseDialog() {
      this.dialog = false
    },
    deleteCompany() {
      this.dialog = false
      this.initialize()
    },
    onCreateCompany(value) {
      this.loading = true
      this.showLoading({ show: true })

      this.$cognito
          .isAuthenticated()
          .then((auth) => {
            this.$axiosM
              .post(
                '/company/admin/update',
                {
                  id: value.id,
                  name: value.name.trim(),
                },
                {
                  headers: {
                    Authorization: auth.idToken,
                  },
                }
              )
              .then(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.initialize()
              })
              .catch(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.showSnack({
                  text: this.$t('alert.update_failed'),
                  color: 'error',
                  timeout: 6000,
                })
              })
              .finally(() => {
                this.showLoading({ show: false })
                this.dialog = false
              })
          })
          .catch((error) => {
            console.error(error)
            this.loading = false
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.login_again'),
              color: 'error',
              timeout: 6000,
            })
            this.$router.replace('/manager/signin')
          })
    },
  },
}
</script>
