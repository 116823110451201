import Vue from 'vue'
import VueI18n from 'vue-i18n'
import jpMessage from '../locales/ja.json'
import enMessage from '../locales/en.json'
import dayjs from 'dayjs'
import jaLocale from 'dayjs/locale/ja'

Vue.use(VueI18n)

const messages = {
  ja: jpMessage,
  en: enMessage,
}

const language = window.navigator.userLanguage || window.navigator.language

const i18n = new VueI18n({
  locale: language == 'ja' ? 'ja' : 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})
dayjs.locale('ja', {
  ...jaLocale,
  weekdays: [
    i18n.t('weekdays.mon'),
    i18n.t('weekdays.tue'),
    i18n.t('weekdays.wed'),
    i18n.t('weekdays.thu'),
    i18n.t('weekdays.fri'),
    i18n.t('weekdays.sat'),
    i18n.t('weekdays.sun'),
  ],
  weekdaysShort: [
    i18n.t('weekdaysShort.mon'),
    i18n.t('weekdaysShort.tue'),
    i18n.t('weekdaysShort.wed'),
    i18n.t('weekdaysShort.thu'),
    i18n.t('weekdaysShort.fri'),
    i18n.t('weekdaysShort.sat'),
    i18n.t('weekdaysShort.sun'),
  ],
})

export default i18n
