<template>
  <v-text-field
    v-on:input="$emit('input', $event)"
    single-line
    clearable
    hide-details
    outlined
    dense
    :label="$t('kindergarten_list.search')"
    prepend-inner-icon="mdi-magnify"
    background-color="#EEEEEE"
    class="input-search"
    style="font-size: 16px"
    v-bind="$attrs"
  />
</template>
<style>
/* Custom Input Search */
.input-search.v-text-field fieldset,
.input-search.v-text-field .v-input__control,
.input-search.v-text-field .v-input__slot {
  border: none;
}
.v-card__title {
  padding-left: 32px;
}
</style>
<script>
export default {}
</script>
