<template>
  <v-main>
    <v-container
      class="d-flex align-center justify-center"
      style="height: 100%"
    >
      <v-card
        width="660"
        height="387"
        class="mx-auto"
        style="border-radius: 12px"
      >
        <v-container style="padding: 40px 80px">
          <v-card-title class="d-block pt-0 pb-5 d-flex justify-center">
            <h1
              class="text-center d-flex align-center"
              style="font-size: 20px; color: #333333"
            >
              <img :src="logo" alt="Logo" class="mr-1" />
              {{ $t('navbar.title_top') }}
            </h1>
          </v-card-title>

          <v-form ref="send_data">
            <v-row>
              <v-col>
                <div>
                  <label style="font-size: 14px; color: #9e9e9e" class="pl-2">{{
                    $t('login.mail')
                  }}</label>
                  <v-text-field
                    height="44"
                    v-model="email"
                    :label="$t('login.mail')"
                    dense
                    single-line
                    outlined
                    background-color="#EEEEEE"
                    :rules="[required]"
                    class="input-text"
                  />
                </div>
                <div>
                  <label style="font-size: 14px; color: #9e9e9e" class="pl-2">{{
                    $t('login.password')
                  }}</label>
                  <v-text-field
                    height="44"
                    v-model="password"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('login.password')"
                    single-line
                    dense
                    outlined
                    background-color="#EEEEEE"
                    :rules="[required]"
                    @click:append="show = !show"
                    class="input-text"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col style="display: flex; justify-content: center">
                <ENJIBtn
                  height="44"
                  color="#6170E8"
                  class="px-6"
                  @click="signIn"
                >
                  {{ $t('navbar.login') }}
                </ENJIBtn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-container>
    <snackbar-vue />
    <loading-message-vue />
  </v-main>
</template>
<style>
.input-text.v-text-field fieldset,
.input-search.v-text-field .v-input__control,
.input-text.v-text-field .v-input__slot {
  border: none;
}

.input-text.v-text-field .v-text-field__details .v-messages__message {
  font-size: 14px;
}
</style>
<script>
import logo from '@/assets/logo-24x24.png'
import SnackbarVue from '@/components/Snackbar.vue'
import LoadingMessageVue from '@/components/LoadingMessage.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn'
export default {
  components: {
    ENJIBtn,
    SnackbarVue,
    LoadingMessageVue
  },
  data() {
    return {
      logo,
      required: (value) => !!value || this.$t('alert.required_text'),
      show: false,
      email: '',
      password: '',
    }
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  methods: {
    signIn() {
      if (this.$refs.send_data.validate()) {
        // 認証データの作成
        this.showLoading({ show: true })
        this.$cognito
          .login(this.email.trim(), this.password)
          .then((result) => {
            this.showLoading({ show: false })
            return this.$router.push('/manager/company/master')
          })
          .catch(() => {
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.login_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
      }
    },
  },
}
</script>
