import axios from 'axios'

const AxiosPlugin = {}

AxiosPlugin.install = function (Vue) {
  Vue.prototype.$axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'x-api-key': process.env.VUE_APP_API_KEY,
    },
    responseType: 'json',
    timeout: 9000,
  })

  // レスポンスログ
  Vue.prototype.$axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      Vue.config.errorHandler(error)
      return Promise.reject(error)
    }
  )

  Vue.prototype.$axiosM = axios.create({
    baseURL: process.env.VUE_APP_MANAGER_API_URL,
    responseType: 'json',
    timeout: 9000,
  })

  // レスポンスログ
  Vue.prototype.$axiosM.interceptors.response.use(
    function (response) {
      return response
    }, 
    function (error) {
      Vue.config.errorHandler(error)
      return Promise.reject(error)
    }
  )
}

export default AxiosPlugin
